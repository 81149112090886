<template>
  <div class="flex overflow-hidden">
    <Sidebar/>
    <div class="flex flex-col p-3 w-4/5 h-screen overflow-y-scroll overflow-x-hidden no-scrollbar">
      <div class="text-4xl font-bold my-6 ml-4">{{ t('customers_list.all_customers') }}</div>
      <a :href="getBackendPath(`/user-new.php`)" target="_blank">
        <button class="bg-primary text-black w-52 rounded-3xl py-3 text-xs flex justify-center
          items-center cursor-pointer font-bold focus:outline-none hover:bg-secondary hover:shadow-lg">
          {{ t('customers_list.add_new_customer') }}
        </button>
      </a>
      <!--          <FilterCustomer/>-->
      <CustomersList/>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/common/Sidebar.vue';
import FilterCustomer from '@/components/FilterCustomer.vue';
import CustomersList from '@/components/CustomersList.vue';
import httpClient from '@/services/httpClient';
import {useI18n} from 'vue-i18n';


export default {
  components: {
    Sidebar,
    FilterCustomer,
    CustomersList
  },

  setup() {
    const getBackendPath = (path) => {
      return httpClient.backend(path);
    }
    const {t} = useI18n();


    return {
      getBackendPath,
      t
    }
  }
}
</script>
