<template>
  <div class="flex flex-col justify-start items-start px-4">
    <div class="flex items-end">
      <SearchBar/>
      <div class="relative flex flex-col w-60 items-start">
          <div class="text-sm text-center">Filter by Payment Status</div>
          <BaseFilter :options="orderStatusList"/>
      </div>
      <div class="relative flex flex-col w-60 items-start">
          <div class="text-sm text-center">Filter by Payment Status</div>
          <BaseFilter :options="paymentStatusList"/>
      </div>
    </div>
  </div>
</template>

<script>
import SearchBar from './SearchBar.vue'
import BaseFilter from './BaseFilter.vue'

export default ({
  name: 'FilterCustomer',
  components: {
    SearchBar,
    BaseFilter
  },
  props: {
  },
  setup() {
      const orderStatusList = ['To do', 'In progress', 'Completed'];
      const paymentStatusList = ['Pending', 'Paid', 'Late', 'Not paid'];
    return {
      orderStatusList,
      paymentStatusList
    };
  },
});

</script>

<style lang="scss" scoped>
</style>
