<template>
  <div class="w-1/5">
    <div class="mt-1">
      <button type="button" class="relative bg-white border w-56 border-gray-300 rounded-md shadow-sm h-10 pl-1 py-1 text-left cursor-pointer focus:outline-none text-xs z-10" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" @click="toggleDropdown">
        <span class="flex items-center">
          <span class="block truncate ml-2">All</span>
          <svg class="absolute right-0 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
          </svg>
        </span>
      </button>
      <ul :class="{'absolute top-6 bg-white shadow-lg w-56 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none z-20 no-scrollbar' : showDropdown, 'hidden' : !showDropdown}" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
        <li class="flex items-center text-gray-900 cursor-pointer select-none relative px-2 text-xs h-10 hover:bg-gray-100" id="listbox-option-0" role="option">
          <input v-if="checkFilterType(filterType)" name="candidates" type="checkbox" class="h-4 w-4 absolute top-3 left-2">
          <input v-else name="candidates" type="radio" class="h-4 w-4 absolute top-3 left-2">
          <span class="ml-6 w-11/12">All</span>
          <button v-if="!openCalendar" type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" @click="toggleDropdown">
            <span class="flex items-center">
              <span class="block truncate ml-2"></span>
              <svg class="absolute right-0 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </button>
        </li>
        <li :key="service.id" v-for="service in options" class="flex items-center text-gray-900 cursor-pointer select-none relative py-2 px-2 text-xs h-10 hover:bg-gray-100" :class="{'bg-gray-200' : openCalendar && service == 'Custom'}" id="listbox-option-0" role="option">
          <input v-if="checkFilterType(filterType)" name="candidates" type="checkbox" class="h-4 w-4 absolute top-3 left-2">
          <input v-else name="candidates" type="radio" class="h-4 w-4 absolute top-3 left-2" @click="customOption(service)">
          <span v-if="service != 'Custom'" class="pl-6 w-11/12">{{service}}</span>
          <span v-else class="pl-6 w-11/12">
            {{service}}
          </span>
          <button v-if="openCalendar && service == 'Custom'" type="button" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" @click="toggleDropdown">
            <span class="flex items-center">
              <span class="block truncate ml-2"></span>
              <svg class="absolute right-0 w-4 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </span>
          </button>
        </li>
        <div v-if="openCalendar" class="relative bg-gray-200 p-2 text-xs">
          <div class="flex items-center justify-between">
            <input type="text" class="relative border rounded-lg h-6 border-gray-300 w-20 px-1 rounded-lg z-0 text-xs outline-none" placeholder="01/05/2020">
            to
            <input type="text" class="relative border rounded-lg h-6 border-gray-300 w-20 px-1 rounded-lg z-0 text-xs outline-none" placeholder="01/06/2020">
          </div>
        </div>
      </ul>
    </div>
  </div>

</template>

<script>
import { ref } from 'vue'

export default ({
  name: 'BaseFilter',
  props: {
      options: Array,
      filterType: String
  },
  setup() {
    const showDropdown = ref(false)
    const currentStatus = ref('');
    const openCalendar = ref(false);

    function toggleDropdown(){
      showDropdown.value = !showDropdown.value
    }

    function setCurrentStatus(newStatus){
      currentStatus.value = newStatus;
      showDropdown.value = false;
    }

    function checkFilterType(filterType){
      if(filterType != 'date')
        return true;
      else
        return false;
    }

    function customOption(option){
      if(option == 'Custom'){
        openCalendar.value = true;
      } else {
        openCalendar.value = false;
      }
    }

    return {
        showDropdown,
        toggleDropdown,
        currentStatus,
        setCurrentStatus,
        checkFilterType,
        openCalendar,
        customOption
    };
  },
});

</script>

<style lang="scss">
input[type='checkbox'],
input[type='radio'] {
    filter: grayscale(1) !important;
}
/* Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
[x-cloak] {
      display: none;
    }
</style>


