<template>
  <div class="flex flex-col">
    <div class="my-2 overflow-x-auto no-scrollbar sm:-mx-6 lg:-mx-8">
      <div class="p-6 align-middle inline-block min-w-full"
           :class="loaded ? '' : 'opacity-40 pointer-events-none'">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mx-6">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr class=" h-12 py-2  text-gray-600 text-xs rounded-lg">
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.customer_name') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.email_address') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.customer_nr') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.roles') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.user_source') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.action') }}
              </th>
            </tr>
            </thead>
            <tbody>

            <template v-for="customer in customers">
              <tr class="h-12 py-2 text-xs hover:bg-gray-100">
                <td class="px-6 py-4 whitespace-nowrap">
                  <router-link :to="`/customer/${customer.userId}`">
                    {{ customer.name }}
                  </router-link>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">{{ customer.email }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ customer.userId }}</td>
                <!-- <td class="px-6 py-4 whitespace-nowrap">{{ customer.roles.join(', ') }}</td> -->
                <td class="px-6 py-4 whitespace-nowrap">{{ t(`user_roles.${customer.roles[0]}`)  }}</td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{customer.user_source ? customer.user_source : "Website"}}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <router-link :to="`/customer/${customer.userId}`" class="w-full">
                    <button
                      class="h-8 border border-primary rounded-3xl p-3 flex justify-center items-center text-xs text-primary hover:bg-primary hover:text-white mr-2 focus:outline-none">
                      {{ t('customers_list.view_profile') }}
                    </button>
                  </router-link>
                </td>
              </tr>
            </template>

            </tbody>
          </table>
        </div>
      </div>

      <div class="p-4 flex justify-center" :class="loaded ? '' : 'opacity-40 pointer-events-none'">
        <v-pagination
          v-model="currentPage"
          :pages="maxPages"
          :range-size="1"
          active-color="rgb(159, 206, 77)"
          @update:modelValue="pageChange"
        />
      </div>

    </div>

    <div class="text-2xl font-bold ml-4 mt-10">
      {{ t('customers_list.unregistered_customers') }}
    </div>

    <div class="my-2 overflow-x-auto no-scrollbar sm:-mx-6 lg:-mx-8">
      <div class="p-6 align-middle inline-block min-w-full"
           :class="loaded ? '' : 'opacity-40 pointer-events-none'">
        <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mx-6">
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
            <tr class=" h-12 py-2  text-gray-600 text-xs rounded-lg">
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.customer_company') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.customer_name') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.email_address') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.customer_phone') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.customer_address') }}
              </th>
              <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                {{ t('customers_list.customer_city') }}
              </th>
            </tr>
            </thead>
            <tbody>

            <template v-for="(customer, i) in guests">
              <tr class="h-12 py-2 text-xs hover:bg-gray-100">
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ customer.company }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  {{ customer.firstName }} {{ customer.lastName }}
                </td>
                <td class="px-6 py-4 whitespace-nowrap">{{ customer.email }}</td>
                <td class="px-6 py-4 whitespace-nowrap">{{ customer.phone }}</td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div>{{ customer.addressLine1 }}</div>
                  <div>{{ customer.addressLine2 }}</div>
                </td>
                <td class="px-6 py-4 whitespace-nowrap">
                  <div>{{ customer.postcode }} {{ customer.city }}</div>
                </td>
              </tr>
            </template>

            </tbody>
          </table>
        </div>
      </div>

      <div class="p-4 flex justify-center" :class="loaded ? '' : 'opacity-40 pointer-events-none'">
        <v-pagination
          v-model="currentGuestPage"
          :pages="maxGuestPages"
          :range-size="1"
          active-color="rgb(159, 206, 77)"
          @update:modelValue="guestPageChange"
        />
      </div>

    </div>
  </div>
</template>

<script>

import {ref, onMounted, watch} from 'vue';
import {useI18n} from 'vue-i18n';
import httpClient from '@/services/httpClient';
import VPagination from '@hennge/vue3-pagination';
import '@hennge/vue3-pagination/dist/vue3-pagination.css';


export default ({
  name: 'CustomersList',
  props: {
    customers: Object
  },
  components: {
    VPagination,
  },
  setup() {
    const {t} = useI18n();
    const customers = ref([]);
    const guests = ref([]);
    const loaded = ref(false);
    const maxPages = ref(1);
    const maxGuestPages = ref(1);
    const currentPage = ref(1);
    const currentGuestPage = ref(1);

    let activeFilters = {
      services: {},
      orderStatus: {},
      paymentStatus: {},
      date: {},
    };

    const fetchCustomers = async (page, filters) => {
      loaded.value = false;

      // const resultWC = await httpClient.get(
      //   '/wc/v3/customers',
      //   {
      //     per_page: 10,
      //     role: 'all',
      //     page: page || 1,
      //     ...filters,
      //   }
      // );
      //
      // console.warn(resultWC);
      // email: "rys@rysie.comx"
      // name: "dgraaa"
      // nicename: "dgr"
      // roles: ["administrator"]
      // status: "0"
      // userId: 1
      const result = await httpClient.get(
        '/api/v1/dgr/customers_paginated',
        {
          page: page || 1,
          ...filters,
        }
      );

      customers.value = result.data.customers;
      maxPages.value = result.data.max_pages;
      loaded.value = true;
    }

    const fetchGuests = async (page, filters) => {
      loaded.value = false;

      const guestsResult = await httpClient.get(
        '/api/v1/dgr/guests_paginated',
        {
          page: page || 1,
          ...filters,
        }
      );

      guests.value = guestsResult.data.customers;
      maxGuestPages.value = guestsResult.data.max_pages;

      console.warn(guests.value);
      loaded.value = true;
    }

    onMounted(async () => {
      await fetchCustomers();
      await fetchGuests();
    })

    async function pageChange(e) {
      await fetchCustomers(e, {... activeFilters });
    }

    async function guestPageChange(e) {
      await fetchGuests(e, {... activeFilters });
    }


    return {
      t,
      pageChange,
      customers,
      loaded,
      maxPages,
      currentPage,
      guests,
      guestPageChange,
      maxGuestPages,
      currentGuestPage,
    };
  },
});

</script>

<style lang="scss" scoped>
</style>


